import Moment from "moment";

export default {
  name: "ClosingEodCentralize",
  computed: {
    isEndOfMonth() {
      if (!this.dataForm.date) {
        return false;
      }
      const endOfMonth = Moment().endOf("month");
      var currDate = Moment(this.dataForm.date);
      var compare = endOfMonth.diff(currDate, "days");
      return compare === 0 ? true : false;
    },
  },
  data() {
    return {
      eodProgressPanel: {
        timer: 1000,
        open: false,
        data: [],
        loading: false,
      },
      property: {
        animation: {
          isDownloadingFile: false,
          closingEodCentralize: {
            isLoading: false,
          },
        },
        listElement: {
          endOfDayResult: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          officeCode: "",
        },
      },
      dataForm: {
        officeName: "",
        date: "",
        is_maintenance: false,
        officeCode: "",
        selectDate: "",
        downloadDate: "",
      },
      table: {
        data: {
          endOfDayResult: [],
        },
      },
    };
  },
  methods: {
    Moment: Moment,
    prepareEndOfDay() {
      Moment.locale("ID");
      const getDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.dataForm.officeName = getDataFromSession.officeName;
      this.dataForm.officeCode = getDataFromSession.officeCode;
      this.dataForm.selectDate = Moment().format("YYYY-MM-DD");
    },
    async fetchMaintaince() {
      this.dataForm.date = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      // this.dataForm.is_maintenance = this.fetchAppSession(
      //   "@vue-session/application"
      // ).is_maintenance;
    },
    async onChangeSystemMaintaince() {
      const confirm = await this.simpleConfirmation(
        "Lanjutkan Proses ?",
        "is-success"
      );
      if (!confirm) {
        return;
      }
      this.property.animation.closingEodCentralize.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "process",
          reqUrl: "system/maintenance",
        });
        if (resp.data.code === "SUCCESS") {
          this.getIsMaintenance();
          window.location.reload();
          return;
        }
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: resp.data.errorFields
            ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
            : resp.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } catch (error) {
        await this.simpleWait(1500);
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.closingEodCentralize.isLoading = false;
      }
    },
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        loanCode: "",
        officeCode: this.dataForm.officeCode,
        date: this.dataForm.downloadDate,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/eod-deposit-profit-share/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          let a = document.createElement("a");
          const blobresp = new Blob([resp.data], { type: "application/pdf" });
          let url = URL.createObjectURL(blobresp);
          a.href = url;
          a.target = "_blank";
          a.click();
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_End_Of_Day.${fileType === "xls" ? "xlsx" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    resetEodProgressPanel(timeout) {
      setTimeout(() => {
        this.eodProgressPanel = {
          timer: 1000,
          open: false,
          data: [],
          status: {},
        };
      }, timeout);
    },
    closeEodProgressPanel() {
      this.resetEodProgressPanel(500);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    async postEodProccess() {
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          reqUrl: "end-of-day-process/v3",
          endPoint: "process",
        });
        if (resp.data.code === "SUCCESS") {
          this.eodProgressPanel.open = true;
          this.eodProgressPanel.loading = true;
          this.streamEod();
          return;
        }
        this.resetEodProgressPanel(0);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: resp.data.errorFields
            ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
            : resp.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } catch (error) {
        this.resetEodProgressPanel(0);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
      }
    },
    async streamEod() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "end-of-day-process/eod-state-process",
          endPoint: "process",
        });
        if (resp.data.code !== "SUCCESS") {
          return;
        }
        const status = resp.data.data[0];
        console.log(status);
        if (status?.value === "IN_PROGRESS" && resp.data.data.length !== 0) {
          this.eodProgressPanel.data = resp.data.data;
          await this.simpleWait(this.eodProgressPanel.timer);
          this.streamEod();
          return;
        }
        if (status?.value === "DONE") {
          this.eodProgressPanel.data = resp.data.data;
        }
        this.eodProgressPanel.loading = false;
      } catch (error) {}
    },
    async processClosingEndOfDay() {
      const confirm = await this.simpleConfirmation(
        "Proses EOD ?",
        "is-success"
      );
      if (!confirm) {
        return;
      }
      this.postEodProccess();
    },
    async getIsMaintenance() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "process",
          reqUrl: "system",
        });
        console.log("status => ", resp);
        if (resp.data.code === "SUCCESS") {
          this.dataForm.is_maintenance = resp.data.data.is_maintenance;
          console.log(this.dataForm.is_maintenance)
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getIsMaintenance();
    this.fetchMaintaince();
    this.prepareEndOfDay();
  },
};
